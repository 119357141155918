/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

import { UpdatedDatePanelController } from "./updatedDatePanel-controller";

declare var angular: angular.IAngularStatic;

import * as moment from 'moment';

//// CONTROLLER ////
export class OrderSearchController {
    // Private data
    private _sessionData: any;

    //Bindable
    public searchParams: any = {};
    public statuses: any = [];
    public orderTypes: any = [];
    public updatedDateSearchText: string = '';
    public receivedDateSearchText: string = '';
    public minDate: any = new Date();
    public isFranchise: boolean;
    public searchAgencies: string = '';
    public searchAdvertiser: string = '';
    public searchBrand: string = '';
    public searchCampaign: string = '';
    public newAdvertiser: boolean = false;
    public newAgency: boolean = false;
    public newBrand: boolean = false;
    public newCampaign: boolean = false;

    static get $inject() {
        return [
            '$state',
            '$stateParams',
            '$scope',
            'loginService',
            'AdvertiserResourceFactory',
            'AgencyResourceFactory',
            'BrandsResourceFactory',
            'CampaignsResourceFactory',
            'EnumService',
            '$mdPanel',
        ];
    }

    constructor(
        public $state: any,
        public $stateParams: any,
        public $scope: any,
        public loginService: any,
        public AdvertiserResourceFactory: any,
        public AgencyResourceFactory: any,
        public BrandsResourceFactory: any,
        public CampaignsResourceFactory: any,
        public EnumService: any,
        public $mdPanel: any,
    ) {
        /* BINDABLE: INJECTS */
        Object.assign(this, {
            // $timeout,
            $state,
            $stateParams,
            $scope,
            loginService,
            AdvertiserResourceFactory,
            AgencyResourceFactory,
            BrandsResourceFactory,
            CampaignsResourceFactory,
            EnumService,
            $mdPanel,
        });

        this._sessionData = loginService.getSessionData();

        /* BINDABLE: DATA */
        this.isFranchise = this._sessionData.franchiseCustomer && !loginService.isAdmin();
    }

    /* IMPLEMENTATION : BINDABLE */
    search() {
        let vm = this;

        if (!vm.searchParams.updatedDateFrom) {
            vm.searchParams.clearedFromDate = true;
        } else {
            vm.searchParams.clearedFromDate = null;
        }

        vm.checkUpdatedDate();
        vm.checkReceivedDate();
        vm.$state.go('orderList', vm.searchParams, {
            notify: true,
            inherit: false,
            reload: true,
        });
    }

    clear() {
        let vm = this;

        vm.searchParams = {};
        vm.receivedDateSearchText = '';
        if (vm._sessionData.ignoreDateRestriction) {
            vm.updatedDateSearchText = '';
        } else {
            vm.minDate = new Date();
            vm.minDate = new Date(
                vm.minDate.getFullYear(),
                vm.minDate.getMonth() - 18,
                vm.minDate.getDate()
            );
            vm.searchParams.updatedDateFrom = vm.minDate;
            vm._setUpdatedDateSearchText();
        }
        // Clear all the autocompletes
        vm.searchAgencies = '';
        vm.searchAdvertiser = '';
        vm.searchBrand = '';
        vm.searchCampaign = '';
    }

    loadAdvertisers(searchName: string) {
        let vm = this;

        return new Promise((resolve, reject) => {
            // This code modification helps prevent the hirachy search blockage for Advertisers COM-18838
            let agencysId = vm.searchParams.agencyId ? vm.searchParams.agencyId : null;
            if (agencysId === null) {
                vm.AdvertiserResourceFactory.getAll(
                    {
                        name: searchName,
                        active: true,
                        limit: 15,
                    }
                ).subscribe(
                    (advertisers: any) => {
                        advertisers.sort(function (a: any, b: any) {
                            return a.name - b.name;
                        });
                        resolve(advertisers);
                    },
                    () => {
                        resolve([]);
                    }
                );
            } else {
                vm.AdvertiserResourceFactory.getAll(
                    {
                        agencyId: agencysId,
                        name: searchName,
                        active: true,
                        limit: 15,
                    }
                ).subscribe(
                    (advertisers: any) => {
                        advertisers.sort(function (a: any, b: any) {
                            return a.name - b.name;
                        });
                        resolve(advertisers);
                    },
                    () => {
                        resolve([]);
                    }
                );
            }

        });
    }

    selectedAdvertiser(advertiser: any) {
        let vm = this;

        if (
            (advertiser && parseInt(advertiser.id, 10) !== parseInt(vm.searchParams.advertiserId, 10)) ||
            advertiser == null
        ) {
            vm.searchBrand = '';
            vm.searchParams.brandId = null;
        }

        vm.searchParams.advertiserId = advertiser ? advertiser.id : null;

        if (vm.searchAdvertiser === '') {
            vm.searchParams.advertiserId = null;
            vm.searchBrand = '';
            vm.searchParams.brandId = null;
        }
        vm.newAdvertiser = true;
    }

    loadAgencies(searchName: string) {
        let vm = this;

        return new Promise((resolve, reject) => {
            vm.AgencyResourceFactory.getAll(
                {
                    name: searchName,
                    activeOnly: true,
                    limit: 15,
                }).subscribe(
                    (agencies: any) => {
                        resolve(agencies.rows);
                    },
                    () => {
                        resolve([]);
                    }
                );
        });
    }

    selectedAgency(agency: any) {
        let vm = this;

        if ((agency && parseInt(agency.id, 10) !== parseInt(vm.searchParams.agencyId, 10)) || agency == null) {
            vm.searchAdvertiser = '';
            vm.searchParams.advertiserId = null;
            vm.searchBrand = '';
            vm.searchParams.brandId = null;
        }

        vm.searchParams.agencyId = agency ? agency.id : null;

        if (vm.searchAgencies === '') {
            vm.searchParams.agencyId = null;
            vm.searchAdvertiser = '';
            vm.searchParams.advertiserId = null;
            vm.searchBrand = '';
            vm.searchParams.brandId = null;
        }

        vm.newAgency = true;
    }

    loadBrands(searchName: string) {
        let vm = this;

        return new Promise((resolve, reject) => {
            // This code modification helps prevent the hirachy search blockage for brands COM-18838
            let advId = vm.searchParams.advertiserId ? vm.searchParams.advertiserId : null;
            if (advId === null) {
                vm.BrandsResourceFactory.getAll(
                    {
                        name: searchName,
                        active: true,
                        limit: 15,
                    }).subscribe(
                        (brands: any) => {
                            resolve(brands);
                        },
                        () => {
                            resolve([]);
                        }
                    );
            } else {
                vm.BrandsResourceFactory.getAll(
                    {
                        advertiserId: vm.searchParams.advertiserId,
                        name: searchName,
                        active: true,
                        limit: 15,
                    }).subscribe(
                        (brands: any) => {
                            resolve(brands);
                        },
                        () => {
                            resolve([]);
                        }
                    );
            }

        });
    }

    selectedBrand(brand: any) {
        let vm = this;

        vm.searchParams.brandId = brand ? brand.id : null;

        if (vm.searchBrand == '') {
            vm.searchParams.brandId = null;
        }
        vm.newBrand = true;
    }

    loadCampaigns(searchName: string) {
        let vm = this;

        return new Promise((resolve, reject) => {
            vm.CampaignsResourceFactory.getAll(
                {
                    brandId: vm.searchParams.brandId,
                    name: searchName,
                    active: true,
                    limit: 15,
                }
            ).subscribe(
                (campaigns:any) => {
                    resolve(campaigns);
                },
                () => {
                    resolve([]);
                }
            );
        });
    }

    selectedCampaign(campaign: any) {
        let vm = this;

        vm.searchParams.campaignId = campaign ? campaign.id : null;

        if (vm.searchCampaign == '') {
            vm.searchParams.campaignId = null;
        }
        vm.newCampaign = true;
    }

    preventEnter(event: any, type: string) {
        let vm = this;

        if (
            event.keyCode === 13 &&
            ((vm.newAgency === false && type === 'agency') ||
                (vm.newAdvertiser === false && type === 'advertiser') ||
                (vm.newBrand === false && type === 'brand') ||
                (vm.newCampaign === false && type === 'campaign'))
        ) {
            event.preventDefault();
        }
        if (event.keyCode !== 13 && type === 'agency') {
            vm.newAgency = false;
        }
        if (event.keyCode !== 13 && type === 'advertiser') {
            vm.newAdvertiser = false;
        }
        if (event.keyCode !== 13 && type === 'brand') {
            vm.newBrand = false;
        }
        if (event.keyCode !== 13 && type === 'campaign') {
            vm.newCampaign = false;
        }
    }

    showUpdatedDate() {
        let vm = this;

        let position = vm.$mdPanel
            .newPanelPosition()
            .relativeTo('.updated-date-input')
            .addPanelPosition(vm.$mdPanel.xPosition.OFFSET_END, vm.$mdPanel.yPosition.CENTER);

        let updatedDatePanel = {
            attachTo: angular.element(document.body),
            controller: UpdatedDatePanelController,
            controllerAs: 'ctrl',
            disableParentScroll: true,
            template: require('../spots/updatedDatePanel-template.html'),
            scope: vm.$scope,
            preserveScope: true,
            panelClass: 'search-popover-panel',
            position,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 2,
            onRemoving() {
                vm._setUpdatedDateSearchText();
            },
            locals: {
                minDate: vm.minDate,
            },
        };

        vm.$mdPanel.open(updatedDatePanel);
    }

    showReceivedDate() {
        let vm = this;

        let position = vm.$mdPanel
            .newPanelPosition()
            .relativeTo('.received-date-input')
            .addPanelPosition(vm.$mdPanel.xPosition.OFFSET_END, vm.$mdPanel.yPosition.CENTER);

        let receivedDatePanel = {
            attachTo: angular.element(document.body),
            controller: function () { },
            controllerAs: 'ctrl',
            disableParentScroll: true,
            template: require('./receivedDatePanel-template.html'),
            scope: vm.$scope,
            preserveScope: true,
            panelClass: 'search-popover-panel',
            position,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true,
            zIndex: 2,
            onRemoving() {
                vm._setReceivedDateSearchText();
            },
        };

        vm.$mdPanel.open(receivedDatePanel);
    }

    checkUpdatedDate() {
        let vm = this;

        if (!vm.updatedDateSearchText) {
            vm.searchParams.updatedDateFrom = null;
            vm.searchParams.updatedDateTo = null;
            vm.searchParams.dateUpdated = null;
            vm.searchParams.clearedFromDate = true;
        } else if (!vm.searchParams.updatedDateFrom) {
            vm.searchParams.updatedDateFrom = null;
            vm.searchParams.clearedFromDate = true;
        } else if (!vm.searchParams.updatedDateTo) {
            vm.searchParams.updatedDateTo = null;
        } else {
            vm.searchParams.clearedFromDate = null;
        }
    }

    checkReceivedDate() {
        let vm = this;

        if (!vm.receivedDateSearchText) {
            vm.searchParams.receivedDateFrom = null;
            vm.searchParams.receivedDateTo = null;
        }
    }

    /* IMPLEMENTATION : PRIVATE */
    _setUpdatedDateSearchText() {
        let vm = this;

        let returnText = '';
        if (!vm.searchParams.updatedDateFrom && !!vm.searchParams.updatedDateTo) {
            returnText =
                'before ' + moment(vm.searchParams.updatedDateTo).format('MM/DD/YYYY');
            vm.searchParams.dateUpdated =
                'lte:' +
                moment(new Date(vm.searchParams.updatedDateTo))
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format('MM-DD-YYYY h:mm:ss a');
        } else if (
            !!vm.searchParams.updatedDateFrom &&
            !vm.searchParams.updatedDateTo
        ) {
            returnText =
                'after ' + moment(vm.searchParams.updatedDateFrom).format('MM/DD/YYYY');
            vm.searchParams.dateUpdated =
                'gte:' +
                moment(new Date(vm.searchParams.updatedDateFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a');
            // If it has both dates
        } else if (
            !!vm.searchParams.updatedDateFrom &&
            !!vm.searchParams.updatedDateTo
        ) {
            returnText =
                moment(vm.searchParams.updatedDateFrom).format('MM/DD/YYYY') +
                ' to ' +
                moment(vm.searchParams.updatedDateTo).format('MM/DD/YYYY');
            vm.searchParams.dateUpdated =
                'bt:[' +
                moment(new Date(vm.searchParams.updatedDateFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a') +
                ',' +
                moment(new Date(vm.searchParams.updatedDateTo))
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format('MM-DD-YYYY h:mm:ss a') +
                ']';
        }
        vm.updatedDateSearchText = returnText;
    }

    _setReceivedDateSearchText() {
        let vm = this;

        let returnText = '';
        if (!vm.searchParams.receivedDateFrom && !!vm.searchParams.receivedDateTo) {
            returnText =
                'before ' + moment(vm.searchParams.receivedDateTo).format('MM/DD/YYYY');
            vm.searchParams.dateReceived =
                'lte:' +
                moment(new Date(vm.searchParams.receivedDateTo))
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format('MM-DD-YYYY h:mm:ss a');
        } else if (
            !!vm.searchParams.receivedDateFrom &&
            !vm.searchParams.receivedDateTo
        ) {
            returnText =
                'after ' +
                moment(vm.searchParams.receivedDateFrom).format('MM/DD/YYYY');
            vm.searchParams.dateReceived =
                'gte:' +
                moment(new Date(vm.searchParams.receivedDateFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a');
            // If it has both dates
        } else if (
            !!vm.searchParams.receivedDateFrom &&
            !!vm.searchParams.receivedDateTo
        ) {
            returnText =
                moment(vm.searchParams.receivedDateFrom).format('MM/DD/YYYY') +
                ' to ' +
                moment(vm.searchParams.receivedDateTo).format('MM/DD/YYYY');
            vm.searchParams.dateReceived =
                'bt:[' +
                moment(new Date(vm.searchParams.receivedDateFrom))
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .format('MM-DD-YYYY h:mm:ss a') +
                ',' +
                moment(new Date(vm.searchParams.receivedDateTo))
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .format('MM-DD-YYYY h:mm:ss a') +
                ']';
        }
        vm.receivedDateSearchText = returnText;
    }

    _filterOrderTypes() {
        let vm = this;

        if (vm.orderTypes && vm.orderTypes.length > 0 && vm._sessionData.account) {
            // only add in allowed order types for the logged-in account
            let tempArray = [];
            for (let i = 0; i < vm.orderTypes.length; i++) {
                switch (vm.orderTypes[i].value) {
                    case 'RADIO':
                        if (vm._sessionData.account.allowRadio) {
                            tempArray.push(vm.orderTypes[i]);
                        }
                        break;
                    case 'TV':
                        if (!vm._sessionData.account.disallowTv) {
                            tempArray.push(vm.orderTypes[i]);
                        }
                        break;
                    case 'PROMO':
                        if (vm._sessionData.account.allowPromo) {
                            tempArray.push(vm.orderTypes[i]);
                        }
                        break;
                    case 'DIGITAL':
                        tempArray.push(vm.orderTypes[i]);
                        break;
                }
            }
            vm.orderTypes = tempArray;
        }
    }

    // All private methods should start with '_' in order to distinguish them
    _setAgencySearchText(agencyId: number) {
        let vm = this;

        vm.AgencyResourceFactory.get({ id: agencyId }).subscribe(
            (agency: any) => {
                vm.searchAgencies = agency.name;
            }
        );
    }

    _setAdvertiserSearchText(advertiserId: number) {
        let vm = this;

        vm.AdvertiserResourceFactory.get({ id: advertiserId }).subscribe(
            (advertiser: any) => {
                vm.searchAdvertiser = advertiser.name;
            }
        );
    }

    _setBrandSearchText(brandId: number) {
        let vm = this;

        vm.BrandsResourceFactory.get({ id: brandId }).subscribe(
            (brand: any) => {
                vm.searchBrand = brand.name;
            }
        );
    }

    _setCampaignSearchText(campaignId: number) {
        let vm = this;

        vm.CampaignsResourceFactory.get({ id: campaignId }).subscribe(
            (campaign:any) => {
                vm.searchCampaign = campaign.name;
            }
        );
    }

    $onInit() {
        let vm = this;

        vm.searchParams = vm.$stateParams;

        vm.EnumService.getEnum('DistributionOrder', 'deliveryStatus').then(
            function (statuses: any) {
                vm.statuses = statuses;
            },
            function () {
                vm.statuses = [];
            }
        );

        vm.EnumService.getEnum('DistributionOrder', 'orderType').then(
            function (orderTypes: any) {
                vm.orderTypes = orderTypes;
                vm._filterOrderTypes();
            },
            function () {
                vm.orderTypes = [];
            }
        );

        vm.searchParams.advertiserId = vm.searchParams.advertiserId
            ? parseInt(vm.searchParams.advertiserId, 10)
            : '';
        vm.searchParams.agencyId = vm.searchParams.agencyId
            ? parseInt(vm.searchParams.agencyId, 10)
            : '';
        vm.searchParams.brandId = vm.searchParams.brandId
            ? parseInt(vm.searchParams.brandId, 10)
            : '';
        vm.searchParams.includeArchives = vm.searchParams.includeArchives === 'true';

        if (!vm._sessionData.ignoreDateRestriction && !vm.searchParams.clearedFromDate) {
            vm.minDate = new Date(
                vm.minDate.getFullYear(),
                vm.minDate.getMonth() - 18,
                vm.minDate.getDate()
            );
            if (!vm.searchParams.updatedDateFrom) {
                vm.searchParams.updatedDateFrom = vm.minDate;
            }
        } else {
            vm.minDate = null;
        }

        // If any of the autocompletes need to be populated with a real name
        if (vm.searchParams.agencyId) {
            vm._setAgencySearchText(vm.searchParams.agencyId);
        }
        if (vm.searchParams.advertiserId) {
            vm._setAdvertiserSearchText(vm.searchParams.advertiserId);
        }
        if (vm.searchParams.brandId) {
            vm._setBrandSearchText(vm.searchParams.brandId);
        }
        if (vm.searchParams.campaignId) {
            vm._setCampaignSearchText(vm.searchParams.campaignId);
        }

        vm._setUpdatedDateSearchText();
        vm._setReceivedDateSearchText();
    }
}
